import React, { useEffect } from "react"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import cn from "classnames"

import * as styles from "./RichText.module.scss"

interface IRichText {
  className?: string
  richText: any
}

const RichText = ({ richText, className }: IRichText) => {
  useEffect(() => {
    const links = document.querySelectorAll(".richText a")
    links.forEach(link => link.setAttribute("target", "_blank"))
  })

  const Bold: React.FC<{}> = ({ children }) => <strong>{children}</strong>
  const Text: React.FC<{}> = ({ children }) => <p>{children}</p>

  const options: any = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        return <Text>{children}</Text>
      },
    },
  }

  return (
    <div className={cn("richText", className, styles.richTextContainer)}>
      {renderRichText(richText, options)}
    </div>
  )
}

export default RichText
