import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ContentfulArtistCollectorRightsQuery } from "../../../types/gatsby-graphql"
import Container from "../../components/Container/Container"
import RichText from "../../components/RichText/RichText"
import Seo from "../../components/Seo/seo"
import SharedHero from "../../components/SharedHero/SharedHero"

import * as styles from "./ArtistCollectorRights.module.scss"

const ArtistCollectorRights = () => {
  const data: ContentfulArtistCollectorRightsQuery = useStaticQuery(graphql`
    query ContentfulArtistCollectorRights {
      contentfulArtistCollectorRights {
        title
        artistRights {
          raw
        }
        collectorRights {
          raw
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Artist & Collector's Rights" />
      <div className={styles.artistCollector}>
        <SharedHero
          title={data.contentfulArtistCollectorRights?.title as string}
          className={styles.ArtistCollectorRightssharedHero}
        />
        <div className={styles.artistCollectorRichTextBox}>
          <RichText
            className={styles.artistCollectorRichText}
            richText={data.contentfulArtistCollectorRights?.artistRights}
          />
          <RichText
            className={styles.artistCollectorRichText}
            richText={data.contentfulArtistCollectorRights?.collectorRights}
          />
        </div>
      </div>
    </>
  )
}

export default ArtistCollectorRights
