import React from "react"

import { ISharedHero } from "./types"
import pinkStripes from "../../images/pinkStripes.png"
import cn from "classnames"
import * as styles from "./SharedHero.module.scss"
import Icon from "../../Icon"

const SharedHero = ({ title, className }: ISharedHero) => {
  return (
    <div className={cn(styles.sharedHero, className)}>
      <img
        src={pinkStripes}
        alt="Background Stripes"
        className={styles.sharedHeroBackground}
      />
      <Icon name="skyBlueSquares" />
      <h1>{title}</h1>
      <img
        src={pinkStripes}
        alt="Background Stripes"
        className={styles.sharedHeroBackground}
      />
    </div>
  )
}

export default SharedHero
